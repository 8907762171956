import {reactive} from "vue";
import request from "@/utils/request";


export const store =reactive({
  goodsDone:false,
  state:{},
  isAdmin:true,
  employeeMain:{},
  // initData:{},
  goodsStoreInfoList:[],
  storeList:[],
  auth:0,
  warnInventory:[],
  addStoreFormVisible:false,
  switchStoreFormVisible:false,
  employeeLoginFormVisible:false,
  switchAdminFormVisible:false,
  getLocalCurrentStoreMain:()=>{
    return JSON.parse(localStorage.getItem("currentStoreMain"))
  },
  getLocalUserMain:()=>{
    return JSON.parse(localStorage.getItem("userMain"))
  },
  resetLocalGoodsInfo:(storeMain)=>{
    request.get("/goods/get/goodsCodeList/"+store.getLocalCurrentStoreMain().storeId).then(res=>{
      for (let i in res.data){
        localStorage.removeItem(res.data[i])
      }
      localStorage.setItem("currentStoreMain",JSON.stringify(storeMain))
      request.get("/goods/get/goodsInfo2/"+storeMain.storeId).then(res=>{
        for (let i in res.data){
          store.setLocalStorage(res.data[i].code,res.data[i])
        }
        console.log("resetLocalGoodsInfo done")
      })
    })
  },
  resetStoreGoodsInfo: ()=>{
    request.get("/goods/get/goodsCodeList/"+store.getLocalCurrentStoreMain().storeId).then(res=>{
      store.goodsStoreInfoList=[]
      for (let i in res.data){
        store.goodsStoreInfoList.push(store.getLocalStorage(res.data[i]))
      }
    })
  },
  getLocalEmployeeMain:()=>{
    return JSON.parse(localStorage.getItem("employeeMain"))
  },
  removeLocalEmployeeMain:()=>{
    localStorage.removeItem("employeeMain")
  },
  setLocalStorage:(key,value)=>{
    if (typeof value==="string")
      localStorage.setItem(key,value)
    else
      localStorage.setItem(key,JSON.stringify(value))
  },
  getLocalStorage:(key)=>{
    try {
      return JSON.parse(localStorage.getItem(key))
    }catch (e){
      return localStorage.getItem(key)
    }
  }

})
