import axios from "axios";
import md5 from "js-md5"
import {store} from "@/store";
import {ElMessage} from "element-plus";
import router from "@/router";

const request = axios.create({
  baseURL:"http://golxen.com/rsms/",
  // baseURL: "http://127.0.0.1:8002",
  // baseURL: "http://localhost:8002",
  transformResponse: [function (data) {
    // 对接收的 data 进行任意转换处理
    return data;
  }],
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么

  console.log(config.url)
  if(config.url==="/store/loginUserOnSendMail"
      || config.url==="/store/loginUser"
      || config.url==="/store/registerUser"){
    config.data.password=md5(config.data.password)
  }

  config.headers['Content-Security-Policy'] = 'upgrade-insecure-requests'
  config.headers['Content-Type'] = 'application/json;charset=utf-8';

  if (store.getLocalStorage("rsms-token")!= null) {
    console.log("localStorage:", store.getLocalStorage("rsms-token"))
    config.headers["rsms-token"] = store.getLocalStorage("rsms-token")
  } else {
    console.log("null token")
    config.headers["rsms-token"] = null
    // message.warning("重新登录")
  }


  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});


// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么


  let res = response.data;
  // 如果是返回的文件
  if (response.config.responseType === 'blob') {
    return res
  }
  // 兼容服务端返回的字符串数据
  if (typeof res === 'string') {
    res = res ? JSON.parse(res) : res
    if (res.code===14){
      ElMessage({
        message: '权限限制',
        type: 'warning',
      })
    }
    if (res.code===10){
      ElMessage({
        message: '未登录',
        type: 'warning',
      })
      router.push("/admin/login")
    }

  }
  return res;

}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default request
