import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "background-color": "#ececec"
  }
};
import { nextTick, onBeforeMount, provide, ref } from "vue";
import { store } from "@/store";
export default {
  __name: 'App',
  setup(__props) {
    const isRouterAlive = ref(true);
    onBeforeMount(() => {
      // 在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem("rsms-store")) {
        store.state = JSON.parse(sessionStorage.getItem("rsms-store"));
        sessionStorage.removeItem("rsms-store");
      } else store.state = {
        selectMenu: "/goods",
        waiting: []
      };

      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload", () => {
        sessionStorage.setItem("rsms-store", JSON.stringify(store.state));
      });
    });
    const reload = () => {
      setTimeout(() => {
        isRouterAlive.value = false;
        nextTick(() => {
          isRouterAlive.value = true;
        });
      }, 300);
    };
    provide("reload", reload);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [isRouterAlive.value ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      })) : _createCommentVNode("", true)]);
    };
  }
};