import {createRouter,createWebHashHistory} from "vue-router"
import {store} from "@/store";



const routes =[
    {
        path:'/admin/login',
        name:'login',
        component:()=>import("@/components/login/index")
    },
    {
        path:'/admin/register',
        component:()=>import("@/components/login/UserRegister")
    },
    {
        path: '/',
        component:()=>import("@/components/index"),
        meta: {
            needLogin: true //需要加校检判断的路由
        },
        redirect: '/goods',
        children:[
            {
                path: '/pay',
                component:()=>import("@/components/pay/index"),
                children:[
                ]
            },
            {
                path: '/goods',
                component:()=>import("@/components/goods/index"),
                children:[
                ]
            },
            {
                path: '/inventory',
                component:()=>import("@/components/inventory/index"),
                redirect: '/inventory/AllInventory',
                children:[
                    {
                        path: '/inventory/AllInventory',
                        component:()=>import("@/components/inventory/AllInventory"),
                    },
                    {
                        path: '/inventory/WarnInventory',
                        component:()=>import("@/components/inventory/WarnInventory"),
                    },
                    {
                        path: '/inventory/FrequentPurchase',
                        component:()=>import("@/components/inventory/FrequentPurchase"),
                    },
                    {
                        path: '/inventory/GoodsPurchase',
                        component:()=>import("@/components/inventory/GoodsPurchase"),
                    },
                    {
                        path: '/inventory/GoodsLog',
                        component:()=>import("@/components/inventory/GoodsLog"),
                    },
                ]
            },
            {
                path: '/business',
                component:()=>import("@/components/business/index"),
                redirect: '/business/Income',
                children:[
                    {
                        path:"/business/Income",
                        component:()=>import("@/components/business/Income"),
                    },
                    {
                        path:"/inventory/Reconcile",
                        component:()=>import("@/components/business/Reconcile"),
                    },
                    {
                        path:"/inventory/SalesFlow",
                        component:()=>import("@/components/business/SalesFlow"),
                    },
                    {
                        path:"/inventory/AnalysisReport",
                        component:()=>import("@/components/business/AnalysisReport"),
                    },
                    {
                        path:"/inventory/BigDataSelection",
                        component:()=>import("@/components/business/BigDataSelection"),
                    },
                    {
                        path:"/inventory/CustomerAnalysis",
                        component:()=>import("@/components/business/CustomerAnalysis"),
                    },
                    {
                        path:"/inventory/SalesRank",
                        component:()=>import("@/components/business/SalesRank"),
                    },
                    {
                        path:"/inventory/PriceAdjustSuggest",
                        component:()=>import("@/components/business/PriceAdjustSuggest"),
                    },
                ]
            },
            {
                path: '/store',
                component:()=>import("@/components/store/index"),
                redirect: '/store/StoreInfo',
                children:[
                    {
                        path:"/store/StoreInfo",
                        component:()=>import("@/components/store/StoreInfo"),
                    },
                    {
                        path:"/store/Employee",
                        component:()=>import("@/components/store/Employee"),
                    },
                    {
                        path:"/store/Offers",
                        component:()=>import("@/components/store/Offers"),
                    }
                ]
            },
        ]
    },


]

// 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    // base:'./',
    routes, // `routes: routes` 的缩写
})

router.beforeEach((to, from,next) => {
    console.log(to.path)
    if (to.meta.needLogin){
        if (store.getLocalStorage("rsms-token")!= null)
            next()
        else
            next({
                name:'login'
            })
    }else{

        next()
    }

})

router.afterEach((to, from) => {
    if(from.name==="preview"){
        // location.reload()
    }
})

export default router;